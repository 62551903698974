import { Route, Routes } from "react-router-dom";
import CookieConsent from "react-cookie-consent";
import React, {Suspense} from "react";

const Navbar = React.lazy(() => import("./navbar/Navbar"));
const Home = React.lazy(() => import("./home/Home"));
const Services = React.lazy(() => import("./services/Services"));
const Apps = React.lazy(() => import("./apps/Apps"));
const About = React.lazy(() => import("./about/About"));
const Footer = React.lazy(() => import("./footer/Footer"));
const Contact = React.lazy(() => import("./contact/Contact"));
const Subscription = React.lazy(() => import("./subscription/Subscription"));
const Blog = React.lazy(() => import("./blog/Blog"));
const NotFound = React.lazy(() => import("./notFound/NotFound"));
const PrivacyContainer = React.lazy(() => import("./privacy/PrivacyContainer"));
const Loading = React.lazy(() => import("./loading/Loading"));


function App() {
  return (
    <>
      <Navbar />
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route path="/">
            <Route index element={
              <>
                <Home />
                <Services />
                <Apps />
                <About />
                <Contact />
              </>
            }/>
            <Route path="/*" element={<NotFound />} />
            <Route path="/subscription" element={<Subscription />} />
            <Route path="/blog/*" element={<Blog />} />
            <Route path="/privacy/*" element={<PrivacyContainer />} />
          </Route>
        </Routes>
      </Suspense>
      <CookieConsent
        location="bottom"
        buttonText="I understand"
        cookieName="cookie"
        style={{ background: "#5F1C9E" }}
        buttonStyle={{ color: "white", fontSize: "13px", background: "#B479E4" }}
        expires={150}>
        <span style={{ color: "white" }}>This website uses cookies to enhance the user experience.</span>
      </CookieConsent>
      <Footer />
    </>
  );
}

export default App;
